body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-list-item-meta {
  margin-bottom: 0 !important;
}

.ant-list-item-meta-title {
  margin-bottom: 0 !important;
  margin-top: 2px !important;
}

.ant-list-item-meta-avatar {
  margin-right: 8px !important;
  font-size: 18px;
}

li.ant-list-item.ant-list-item-no-flex {
  padding: 4px;
}

.contact-btn {
  /*background: #yourColor;*/
  /*border-color: rgba(0,0,0,0);*/
}

.contact-btn:hover {
  background: #FFFFFF !important;
  border-color: #555555 !important;
  color: #000000 !important;
}

.ant-rate-star {
  margin:0 !important;
}

